import React from 'react';

class FastNotionCapture extends React.Component {
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">

          {/* SINGLE POST */}
          <section id="single-post" className="bg_whitesmoke hero-offset-nav pb-80 single-post-section division">
            <div className="container">
              {/* SINGLE POST CONTENT */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="single-post-wrapper">
                    {/* SINGLE POST TITLE */}
                    <div className="single-post-title">
                      {/* CATEGORY */}
                      <p className="p-lg post-tag skyblue-color">Instant Notion articles</p>
                      {/* TITLE */}
                      <h4 className="h4-xl">Introducing the Instant Notion iOS App: Capture Notes to Notion with Ease</h4>
                      {/* POST DATA */}
                      <div className="post-data clearfix">

                      </div>
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="single-post-txt">
                      {/* Text */}
                      <p className="p-lg">Introducing Instant Notion, the ultimate iOS app for quick note capture directly to your Notion workspace. With Instant Notion, you can capture and organize your thoughts effortlessly, saving valuable time and effort. Say goodbye to the hassle of switching between apps and streamline your note-taking process.</p>
                    </div>
                    {/* BLOG POST INNER IMAGE */}
                    <a href={`https://apps.apple.com/app/apple-store/id1639385303?pt=118443195&ct=landingpage&mt=8'}`}>
                      <div className="post-inner-img">
                        <img className="img-fluid" src={require('../assets/images/blog/inner-img-1.jpg')} alt="blog-post" />
                      </div>
                    </a>
                    {/* BLOG POST TEXT */}
                    <div className="single-post-txt">
                      {/* Text */}
                      <p className="p-lg">Instant Notion comes packed with powerful features to enhance your note-taking experience:</p>
                      {/* List */}
                      <ul className="digit-list">
                        <li><p className="p-lg">Start the app in an instant with the lock screen widget, allowing you to capture notes quickly and efficiently.</p></li>
                        <li><p className="p-lg">Tag your notes effortlessly, utilizing the tags from your Notion database to maintain consistency and organization.</p></li>
                        <li><p className="p-lg">Save your notes offline, even when you're not connected to the internet. Your notes will be sent to Notion after you come back online</p></li>
                      </ul>
                      {/* Text */}
                      <p className="p-lg">Instant Notion revolutionizes the way you capture and save notes to Notion on your iPhone. Download the app now and experience fast and efficient note-taking. Fast Notion can be a reality, try it out today.</p>
                      <div className="stores-badge mb-5">
                        {/* app store image */}
                        {
                          <a href={`https://apps.apple.com/app/apple-store/id1639385303?pt=118443195&ct=landingpage&mt=8'}`} className="store">
                            <img className="appstore-original" src={require(`../assets/images/store/appstore.png`)} alt="appstore-badge" />
                          </a>
                        }
                      </div>
                    </div>

                    <div className="other-posts">
                      <div id="op-row" className="row d-flex align-items-center">
                        {/* Previous Post */}
                        <div className="col-md-5">
                          <div className="prev-post mb-30 pr-45">
                            <h5 className="h5-sm">Next post</h5>
                            <a href="/articles/how-to-take-good-notes">How to take good notes</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* GEOMETRIC OVERLAY */}
            <div className="bg_fixed geometric_overlay" />
          </section>

        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default FastNotionCapture;