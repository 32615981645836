import React from 'react';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Background Like this class "bg_chat | bg_fit_01 | bg_fit_02 | bg_mobile | bg_bank_01 | bg_bank_02 | bg_purple_img | bg_sblue_img | bg_selfy | bg_pattern_01 | bg_pattern_02 | bg_pattern_03"
//                         ADD extra class like pt-1/100, pb-1/100, pl-1/100, pr-1/100 and "Your Own Class".
// (2) sec_title = section title
// (3) brand_images = set image name set [brand-"1" to brand-"*"]

const Brand = (props) => {
  return (
    <>
      <section id="brands-2" className={`wide-70 brands-section division ${!props.section_class == '' ? props.section_class : ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="brands-title text-center">
                <h4 className="h4-md">{!props.sec_title == '' ? props.sec_title : ''}</h4>
              </div>
            </div>
          </div>  
          <div className="brands-2-wrapper">
            <div className="row">
              <div className="col-md-12">
                {
                  !props.brand_images == ''
                    ?
                    props.brand_images.map((item,index) => {
                      return (
                        <div className="brand-logo" key={'brand_'+ index + 1}>
                          <a href="#/">
                            <img className="img-fluid" src={require(`../assets/images/brand/brand-${item}.png`)} alt="brand-logo" />
                          </a>
                        </div>
                      )
                    })
                    : ''
                }                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Brand;
