import React from 'react';
import WOW from "wowjs";
import BrandSlider from '../components/Brand-Slider';
import Banner9 from '../components/main-banner/Banner9';
import Info_Img_Content from '../components/Info_Img_Content';
import Info_Content_Img from '../components/Info_Content_Img';
import Features_Info_9 from '../components/Features_Info_9';
import Features_Info from '../components/Features_Info';
import Reviews from '../components/Reviews';
import Accessible_All_Platform_Banner from '../components/Accessible_All_Platform_Banner';
import Download_bgImage_Banner from '../components/banner/Download_bgImage_Banner';
import Pricing from '../components/Pricing';
import Faq_simple from '../components/Faq_simple';
import NewsLetter from '../components/NewsLetter';
import Blog from '../components/Blog';
import MoreApps from '../components/MoreApps';

class Home9 extends React.Component {
  componentDidMount() {
    const wow = new WOW.WOW(
      { live: false }
    );
    wow.init();
  }
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">

          {/* BANNER-NINE */}
          <Banner9
            // All Props information is preset in "components/main-banner/Banner9.jsx"
            section_class={``}
            banner_image={`home/hero-9-img.png`}
            font_color={``}
            banner_subtitle={`DesCode®`}
            banner_title={`Showcase Your App in Easy Way`}
            banner_description={`Feugiat primis ligula risus an auctor egestas augue viverra mauri at tortor iaculis magna feugiat mauris ipsum undo viverra tortor and placerat gravida`}
            button_content={`Get Started`}
            button_bg_and_hover={``}
            info_description={`Try DesCode free for 14 days. Cancel anytime.`}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`wide-80`}
            sub_title={`Beauty of Simplicity`}
            title={`Beautiful, award-winning design`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus at blandit purus ipsum primis and cubilia laoreet augue at luctus magna dolor luctus mauris pretium a sapien egestas luctus`,
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus and egestas sapien egestas vitae nemo volute`
              ]
            }
            button={``}
            button_bg_and_hover={``}
            button_content={``}
            rtl_image={`home/img-03.png`}
            img_Class={`pc-15`}
            bgShape_design_1={''}
            bgShape_design_2={''}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`pb-80`}
            lft_image={`home/img-02.png`}
            img_Class={``}
            sub_title={`Perfect Integration`}
            title={`Work smarter with powerful features`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          <Features_Info_9
            // All Props information is preset in "Features_Info_9.Jsx"
            section_class={``}
            sub_title={`Flexible Features`}
            sec_title={`Simple to use, easy to love`}
            p_content_data={
              [
                `Semper lacus cursus porta, feugiat primis and donec ultrice ligula tempus an auctor ipsum nihil mauris lectus enim ipsum sagittis congue`,
                `Gravida porta velna vitae auctor congue magna nihil impedit ligula risus mauris donec ligula`
              ]
            }
            info_data={
              [
                {
                  icon: 'flaticon-ads',
                  title: 'Friendly Interface',
                  description: 'Porta semper lacus cursus feugiat primis ultrice ligula risus auctor ultrice ociis'
                },
                {
                  icon: 'flaticon-switch',
                  title: 'Powerful Options',
                  description: 'Porta semper lacus cursus feugiat primis ultrice ligula risus auctor ultrice ociis'
                },
                {
                  icon: 'flaticon-pantone',
                  title: 'Customization',
                  description: 'Porta semper lacus cursus feugiat primis ultrice ligula risus auctor ultrice ociis'
                },
                {
                  icon: 'flaticon-user-1',
                  title: 'Multiple Accounts',
                  description: 'Porta semper lacus cursus feugiat primis ultrice ligula risus auctor ultrice ociis'
                }
              ]
            }
            icon_color={``}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-80`}
            lft_image={`home/img-08.png`}
            img_Class={``}
            sub_title={`Unlimited Access`}
            title={`More productivity with less effort`}
            p_content_data={
              [
                `Gravida porta velna vitae auctor congue at magna nihil impedit ligula risus mauris donec a ligula risus`,
                `Aliqum mullam blandit and tempor sapien donec lipsum gravida porta. Velna vitae auctor congue magna impedit ligula risus. Mauris donec ligula an impedit magnis`
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* FEATURES-INFO */}
          <Features_Info
            // All Props information is preset in "components/Features_info.jsx"
            layout_style={5}
            section_class={`pb-50`}
            sec_title={`Ready to Try Descode?`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            info_data={[
              {
                icon: `flaticon-user-1`,
                title: `Multiple Accounts`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-smartphone-1`,
                title: `Text, Voice & Video Calls`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-image`,
                title: `Share Files & Media`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-chat-4`,
                title: `Alerts & Notifications`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-fingerprint`,
                title: `Online Verification`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-spam`,
                title: `Spam Protection`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              }
            ]}
            icon_color={'stateblue-color'}
          />

          {/* REVIEWS SIMPLE */}
          <Reviews
            // All Props information is preset in "components/Reviews.jsx"
            section_class={`bg_aliceblue wide-60`}
            section_title={`Why People Use DesCode®`}
            section_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
            app_store_img={`store/appstore.png`}
            appstore_link={``}
            play_store_img={`store/googleplay.png`}
            playstore_link={``}
            app_version_desc={`Available for iOS 8 and Android Devices From 5.5`}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`wide-60`}
            sub_title={`Totally Optimized`}
            title={`Intuitive features, powerful results`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`,
              ]
            }
            button={true}
            button_bg_and_hover={`btn-tra-grey skyblue-hover`}
            button_content={`Find Out More`}
            rtl_image={`home/tablet.png`}
            img_Class={`content-13-img`}
            bgShape_design_1={''}
            bgShape_design_2={''}
          />


          {/* BRANDS-SLIDER */}
          <BrandSlider
            // All Props information is preset in "Brand-Slider.Jsx"
            section_class={`bg_whitesmoke `}
            sec_title={`You might know Descode from:`}
            brand_images={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          />

          {/* ACCESSIBLE ALL PLATFORM */}
          <Accessible_All_Platform_Banner
            // All Props information is preset in "components/Accessible_All_Platform_Banner.jsx"
            section_class={`wide-100`}
            sec_title={`Accessible for All Platforms`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            sec_img={`home/browsers.png`}
            button={true} // true/false
            button_content={`See Descode in Action`}
            button_bg_and_hover={`btn-grey skyblue-hover`} // btn-tra-grey skyblue-hover
            button_icon={``} // flaticon-play-button
            button_description={`Request OS X 10.10 or later`}
          />

          {/* DOWNLOAD BG_IMAGE BANNER*/}
          <Download_bgImage_Banner
            // All Props information is preset in "components/Download_bg_img.jsx"
            section_class={'bg_chat'}
            font_color={`white-color`}
            sub_title={`Powerful Settings`}
            title={`Take Full Advantage of Smart Features`}
            description={`Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit posuere orci auctor purus euismod an aliquam quaerat purus`}
            img1={'store/appstore.png'}
            img2={'store/googleplay.png'}
          />

          {/* PRICING */}
          <Pricing
            // All Props information is preset in "components/Pricing.jsx"		
            section_class={`wide-90 `}
            sec_title={`Simple and Flexible Pricing`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`wide-60 bg_whitesmoke`}
            sub_title={`Register in 30 Seconds`}
            title={`Lightning fast and super powerful`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat sodales a sapien`,
              ]
            }
            button={``}
            button_bg_and_hover={``}
            button_content={``}
            rtl_image={`home/img-01.png`}
            img_Class={``}
            bgShape_design_1={''}
            bgShape_design_2={''}
          />

          {/* FAQS-SIMPLE*/}
          <Faq_simple
            // All Props information is preset in "components/Faq_simple.jsx"	
            section_class={`wide-100`}
            sec_title={`Got Questions? Look Here`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
          />

          {/* NEWSLETTER */}
          <NewsLetter
            // All Props information is preset in "components/NewsLetter.jsx"	
            section_class={``}
            sub_title={`Subscribe to Our Newsletter`}
            title={`Stay up to date with our news, ideas and updates`}
            input_placeholder={``}
            btn_content={`Subscribe Now`}
            button_bg_and_hover={`btn-skyblue tra-skyblue-hover`}
          />

          {/* BLOG */}
          <Blog
            // All Props information is preset in "components/Blog.jsx"	
            section_class={``}
            sec_title={`Our Stories & Latest News`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
            itemStart={3}
            itemEnd={6}
          />
          
          {/* MORE APPS */}
					<MoreApps
						// All Props information is preset in "components/MoreApps.jsx"
						section_class={`bg_whitesmoke wide-70 `}
						sec_title={`More Apps by CODEX Theme`}
						sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
					/>

        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default Home9; 