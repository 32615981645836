import React from 'react';
import Section_Title from './Section_Title';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Background Like this class "bg_chat | bg_fit_01 | bg_fit_02 | bg_mobile | bg_bank_01 | bg_bank_02 | bg_purple_img | bg_sblue_img | bg_selfy | bg_pattern_01 | bg_pattern_02 | bg_pattern_03"
//                      ADD extra class like pt-1/100, pb-1/100, pl-1/100, pr-1/100 and "Your Own Class".
// (2) sub_title = Section content sub Title
// (3) sec_title = Section content title
// (4) p_content_data || ul_content_data = Section content Description

// (4) info_data = Features box content add array in object like this key format {icon:` `, title:` `, description:` `}
//     info_data={[
//                   {
//                      icon:`ICON-CLASS-NAME`,
//                      title:`TITLE`,
//                      description:`DESCRIPTION`
//                    }
//                ]}
// (5) icon_color = Feature box icon color like (white-color | dark-color | grey-color | lightgrey-color | blue-color | green-color | purple-color | rose-color | sapphire-color | skyblue-color | stateblue-color | violet-color | yellow-color)

const Features_Info_9 = (props) => {
    return (
        <>
            <section id="features-13" className={`rel aliceblue_shape features-section division ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className="container">
                    <div className="row d-flex align-items-center">
                        {/* TEXT BLOCK */}
                        <div className="col-md-10 col-lg-5">
                            <div className="txt-block pc-15 wow fadeInUp" data-wow-delay="0.4s">

                                {/* Sub title*/}
                                {!props.sub_title == '' ? <span className="section-id grey-color">{props.sub_title}</span> : ''}

                                {/* Title */}
                                {!props.sec_title == '' ? <h2 className="h2-md">{props.sec_title}</h2> : ''}

                                {/*START: P Tag Structure Description ------(1)*/}
                                {
                                    !props.p_content_data == ''
                                        ?
                                        props.p_content_data.map((item, index) =>
                                            !item == '' ? <p className="p-lg" key={index}>{item}</p> : ''
                                        )
                                        : ''
                                }                                
                                {/*START: Ul Li Tag Structure Description ------(2)*/}
                                {
                                    !props.ul_content_data == ''
                                        ?
                                        <ul className="simple-list">
                                            {
                                                props.ul_content_data.map((item, index) =>
                                                    <li className="list-item" key={index}>
                                                        {!item == '' ? <p className="p-lg">{item}</p> : ''}
                                                    </li>
                                                )
                                            }
                                        </ul>
                                        : ''
                                }
                            </div>
                        </div>
                        {/* FEATURES-13 WRAPPER */}
                        <div className="col-lg-7">
                            <div className="fbox-13-wrapper pl-35">
                                <div className="row_grid">
                                    {
                                        !props.info_data == ''
                                            ?
                                            props.info_data.slice(0, 4).map((item, index) => {
                                                return (
                                                    <div data-wow-delay="0.4s" key={'content_' + index + 1}
                                                        className={`fbox-13 wow fadeInUp ${index + 1 == 1 ? 'mt-50 mb-30' : ''} ${index + 1 == 4 ? 'mt-inverse-50' : ''}`}>

                                                        {/* FEATURES box Icon */}
                                                        <div className={`fbox-13-ico ico-75 ${!props.icon_color == '' ? props.icon_color : ''}`}>
                                                            <span className={item.icon} />
                                                        </div>

                                                        {/* FEATURES box Title */}
                                                        {!item.title == '' ? <h5 className="h5-sm">{item.title}</h5> : ''}

                                                        {/* FEATURES box Desription */}
                                                        {!item.description == '' ? <p className="p-lg">{item.description}</p> : ''}
                                                    </div>
                                                )
                                            })
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Features_Info_9;
