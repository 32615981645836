import React from 'react';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Background Like this class "bg_chat | bg_fit_01 | bg_fit_02 | bg_mobile | bg_bank_01 | bg_bank_02 | bg_purple_img | bg_sblue_img | bg_selfy | bg_pattern_01 | bg_pattern_02 | bg_pattern_03"
//                      ADD extra class like pt-1/100, pb-1/100, pl-1/100, pr-1/100 and "Your Own Class".
// (2) sec_title = Section title

const ProgessbarSec = (props) => {
    return (
        <>
            <section id="process-2" className={`process-section division ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className="container">
                    {
                        !props.sec_title == ''
                            ?
                            <div className="row">
                                <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                                    <div className="section-title text-center mb-60">
                                        <h3 className="h3-sm">{props.sec_title}</h3>
                                    </div>
                                </div>
                            </div>
                            : ''
                    }
                    <div className="row">
                        <ul className="process-skyblue processbar pc-35">
                            {/* PROCESS BOX #1 */}
                            <li id="step-2-1" className="col-md-4">
                                <div className="pbox-2 pc-20 text-center">
                                    <h5 className="h5-sm">Create an Account</h5>
                                    <p className="p-lg">Nemo ipsam egestas volute dolores quaerat sodales</p>
                                </div>
                            </li>
                            {/* PROCESS BOX #2 */}
                            <li id="step-2-2" className="col-md-4">
                                <div className="pbox-2 pc-20 text-center">
                                    <h5 className="h5-sm">Customize Profile</h5>
                                    <p className="p-lg">Nemo ipsam egestas volute dolores quaerat sodales</p>
                                </div>
                            </li>
                            {/* PROCESS BOX #3 */}
                            <li id="step-2-3" className="col-md-4">
                                <div className="pbox-2 pc-20 text-center">
                                    <h5 className="h5-sm">Get Access</h5>
                                    <p className="p-lg">Nemo ipsam egestas volute dolores quaerat sodales</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProgessbarSec;
