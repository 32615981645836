import React from 'react';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  --Background Like this class "bg_pattern_01 | bg_pattern_02 | bg_pattern_03" | bg_purple_img | bg_sblue_img | bg_selfy | blue_gradient. 
//                         Other options find in style.css(Background Colors && Background Gradients && Background Images for Sections) 
//                      --ADD extra class like (pt-1/100, pb-1/100, pl-1/100, pr-1/100, "Your Own Class").
// (2) font_color = Content font color like (white-color | dark-color | grey-color | lightgrey-color | blue-color | green-color | purple-color | rose-color | sapphire-color | skyblue-color | stateblue-color | violet-color | yellow-color)
// (3) banner_sub_title = Banner Sub Title
// (4) banner_title = Banner title
// (5) banner_description = Banner discription
// (6) app_store_img = App store image
// (7) appstore_link = App store link
// (8) play_store_img = Play store image
// (9) playstore_link = play store link
// (10) banner_main_img = Banner Main section image
// (11) user_Count = Description in Users

const Banner8 = (props) => {
    return (
        <>
            <section id="hero-8" className={`bg_fixed hero-section division ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className={`container ${!props.font_color == '' ? props.font_color : ''}`}>
                    <div className="row d-flex align-items-center m-row">
                        {/* Banner Content */}
                        <div className={props.banner_main_img == undefined ? 'col-12 text-center ' : 'col-lg-6 col-xl-6 m-bottom'}>
                            <div className="hero-8-txt pc-25">

                                {/* Sub Title  */}
                                {!props.banner_sub_title == '' ? <h2 className="h4-sm">{props.banner_sub_title}</h2> : ''}

                                {/* Title  */}
                                {!props.banner_title == '' ? <h2 className="h2-sm">{props.banner_title}</h2> : ''}

                                {/* Description */}
                                {!props.banner_description == '' ? <h5 className="h5-xl">{props.banner_description}</h5> : ''}

                                {/* Store Badges */}
                                <div className="stores-badge">
                                    {/* App store image */}
                                    {
                                        !props.app_store_img == ''
                                            ?
                                            <a href={`${!props.appstore_link == '' ? props.appstore_link : '#/'}`} className="store">
                                                <img className="appstore" src={require(`../../assets/images/${props.app_store_img}`)} alt="appstore-badge" />
                                            </a>
                                            : ''
                                    }

                                    {/* Google Play store image */}
                                    {
                                        !props.play_store_img == ''
                                            ?
                                            <a href={`${!props.playstore_link == '' ? props.playstore_link : '#/'}`} className="store">
                                                <img className="googleplay" src={require(`../../assets/images/${props.play_store_img}`)} alt="googleplay-badge" />
                                            </a>
                                            : ''
                                    }
                                </div>

                                {/* Rating */}
                                <div className="txt-block-rating">
                                    <div className="stars-rating">
                                        Customers Rating
                                        <span className="flaticon-star ml-5" />
                                        <span className="flaticon-star" />
                                        <span className="flaticon-star" />
                                        <span className="flaticon-star" />
                                        <span className="flaticon-star-half-empty" />
                                    </div>
                                    <span className="txt-rating">Based on {!props.user_Count == '' ? props.user_Count : '****'} user reviews</span>
                                </div>
                            </div>
                        </div>

                        {/* Banner Main image */}
                        {
                            !props.banner_main_img == ''
                                ?
                                <div className="col-lg-6 col-xl-6 11offset-xl-1 m-top">
                                    <div className="hero-8-img pr-20 text-center wow fadeInDown" data-wow-delay="0.4s">
                                        <img className="img-fluid" src={require(`../../assets/images/${props.banner_main_img}`)} alt="hero" />
                                    </div>
                                </div>
                                : ''
                        }
                    </div>
                </div>
            </section>
        </>
    );
}

export default Banner8;
