import React from 'react';
import { Link } from "react-router-dom";
import WOW from "wowjs";
import '../assets/css/demo/demo.css';

class Demo extends React.Component {
  componentDidMount() {
    const wow = new WOW.WOW(
      { live: false }
    );
    wow.init();
  }
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page-wrapper">
          {/* HERO */}
          <section id="hero" className="bg-fixed hero-section division">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="hero-txt white-color text-center">
                    <span className="version">HTML</span>
                    <h2>Premium HTML5 App Landing Pages Pack</h2>
                    <p>A great collection of flexible, ultra modern &amp; creative landing page templates to promote your mobile App, services or business projects
                    </p>
                  </div>
                  <div className="hero-img"><img className="img-fluid" src={require('../assets/images/demo/hero-img.png')} alt="hero" /></div>
                </div>
              </div>
            </div>
          </section>

          {/* SECTION DIVIDER */}
          <div className="divider-wrapper text-center"><div className="section-divider" /></div>

          {/* SELECT */}
          <section id="select" className="bg-scroll select-section division">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 section-title">
                  <h2>Simple. Creative. Powerful</h2>
                  <p>Descode offers you 12 customizable layouts, with which you can build your website. Choose the one  you like best
                    and start customize it to your website
                  </p>
                </div>
              </div>
              <div className="row">
                {/* LAYOUT-1 */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="0.4s">
                  <div className="hover-overlay">
                    <Link to="/home">
                      <img className="img-fluid" src={require('../assets/images/demo/layout-1.jpg')} alt="layout-1-preview" />
                    </Link>
                  </div>
                  <h4>Layout #1</h4>
                </div>
                {/* LAYOUT-2 */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="0.6s">
                  <div className="hover-overlay">
                    <Link to="/Home2">
                      <img className="img-fluid" src={require('../assets/images/demo/layout-2.jpg')} alt="layout-2-preview" />
                    </Link>
                  </div>
                  <h4>Layout #2</h4>
                </div>
                {/* LAYOUT-3 */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="0.8s">
                  <div className="hover-overlay">
                    <Link to="/Home3">
                      <img className="img-fluid" src={require('../assets/images/demo/layout-3.jpg')} alt="layout-3-preview" />
                    </Link>
                  </div>
                  <h4>Layout #3</h4>
                </div>
                {/* LAYOUT-4 */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="1s">
                  <div className="hover-overlay">
                    <Link to="/Home4">
                      <img className="img-fluid" src={require('../assets/images/demo/layout-4.jpg')} alt="layout-4-preview" />
                    </Link>
                  </div>
                  <h4>Layout #4</h4>
                </div>
                {/* LAYOUT-5 */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="1.2s">
                  <div className="hover-overlay">
                    <Link to="/Home5">
                      <img className="img-fluid" src={require('../assets/images/demo/layout-5.jpg')} alt="layout-5-preview" />
                    </Link>
                  </div>
                  <h4>Layout #5</h4>
                </div>
                {/* LAYOUT-6 */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="1.4s">
                  <div className="hover-overlay">
                    <Link to="/Home6">
                      <img className="img-fluid" src={require('../assets/images/demo/layout-6.jpg')} alt="layout-6-preview" />
                    </Link>
                  </div>
                  <h4>Layout #6</h4>
                </div>
                {/* LAYOUT-7 */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="1.6s">
                  <div className="hover-overlay">
                    <Link to="/Home7">
                      <img className="img-fluid" src={require('../assets/images/demo/layout-7.jpg')} alt="layout-7-preview" />
                    </Link>
                  </div>
                  <h4>Layout #7</h4>
                </div>
                {/* LAYOUT-8 */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="1.8s">
                  <div className="hover-overlay">
                    <Link to="/Home8">
                      <img className="img-fluid" src={require('../assets/images/demo/layout-8.jpg')} alt="layout-8-preview" />
                    </Link>
                  </div>
                  <h4>Layout #8</h4>
                </div>
                {/* LAYOUT-9 */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="2s">
                  <div className="hover-overlay">
                    <Link to="/Home9">
                      <img className="img-fluid" src={require('../assets/images/demo/layout-9.jpg')} alt="layout-9-preview" />
                    </Link>
                  </div>
                  <h4>Layout #9</h4>
                </div>
                {/* LAYOUT-10 */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="2.2s">
                  <div className="hover-overlay">
                    <Link to="/Home10">
                      <img className="img-fluid" src={require('../assets/images/demo/layout-10.jpg')} alt="layout-10-preview" />
                    </Link>
                  </div>
                  <h4>Layout #10</h4>
                </div>

                {/* LAYOUT-11 */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="2.6s">
                  <div className="hover-overlay">
                    <img className="img-fluid" src={require('../assets/images/demo/empty.png')} alt="empty" />
                  </div>
                  <h4>Coming Soon... LAYOUT-11 </h4>
                </div>

                {/* LAYOUT-12 */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="2.6s">
                  <div className="hover-overlay">
                    <img className="img-fluid" src={require('../assets/images/demo/empty.png')} alt="empty" />
                  </div>
                  <h4>Coming Soon...LAYOUT-12</h4>
                </div>
              </div>
            </div>
          </section>

          {/* BANNER-2 */}
          <div id="banner-2" className="banner-section division">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-6">
                  <div className="banner-2-txt white-color">
                    <span className="section-id">Fully Responsive</span>
                    <h2>Works Perfectly on Any Device</h2>
                    <p>Descode is fully responsive, adapt the device size, pixel perfect and all elements look beautiful in all standard and
                      Retina devices and resolutions.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-11">
                    <div className="banner-2-img">
                      <img className="img-fluid" src={require('../assets/images/demo/banner-2-img.png')} alt="banner" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* INNER PAGES */}
          <section id="i-pages" className="ip-section division">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 section-title">
                  <h2>10 Amazing Inner Pages</h2>
                  <p>Descode offers a vast number of pre-made inner pages which will save your time when building a website. Select a page and check it out!
                  </p>
                </div>
              </div>
              <div className="row">
                {/* FEATURES PAGE */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="0.6s">
                  <div className="hover-overlay">
                    <Link to="/features">
                      <img className="img-fluid" src={require('../assets/images/demo/features.jpg')} alt="features-preview" />
                    </Link>
                  </div>
                  <h4>Features Page</h4>
                </div>
                {/* PRICING PLANS */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="0.8s">
                  <div className="hover-overlay">
                    <Link to="/pricing">
                      <img className="img-fluid" src={require('../assets/images/demo/pricing.jpg')} alt="pricing-preview" />
                    </Link>
                  </div>
                  <h4>Pricing Page</h4>
                </div>
                {/* REVIEWS PAGE */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="1s">
                  <div className="hover-overlay">
                    <Link to="/reviews">
                      <img className="img-fluid" src={require('../assets/images/demo/reviews.jpg')} alt="reviews-preview" />
                    </Link>
                  </div>
                  <h4>Reviews Page</h4>
                </div>
                {/* MORE APPS */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="1.2s">
                  <div className="hover-overlay">
                    <Link to="/more_apps">
                      <img className="img-fluid" src={require('../assets/images/demo/more-apps.jpg')} alt="more-apps-preview" />
                    </Link>
                  </div>
                  <h4>More Apps</h4>
                </div>
                {/* FAQs PAGE */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="1.4s">
                  <div className="hover-overlay">
                    <Link to="/faqs">
                      <img className="img-fluid" src={require('../assets/images/demo/faqs.jpg')} alt="faqs-preview" />
                    </Link>
                  </div>
                  <h4>FAQs Page</h4>
                </div>
                {/* TERMS & PRIVACY */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="1.5s">
                  <div className="hover-overlay">
                    <Link to="/terms">
                      <img className="img-fluid" src={require('../assets/images/demo/terms.jpg')} alt="terms-preview" />
                    </Link>
                  </div>
                  <h4>Terms &amp; Privacy</h4>
                </div>
                {/* BLOG LISTING */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="1.7s">
                  <div className="hover-overlay">
                    <Link to="/blog_listing">
                      <img className="img-fluid" src={require('../assets/images/demo/blog-listing.jpg')} alt="blog-listing-preview" />
                    </Link>
                  </div>
                  <h4>Blog Listing</h4>
                </div>
                {/* SINGLE POST */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="1.9s">
                  <div className="hover-overlay">
                    <Link to="/descode/SinglePost">
                      <img className="img-fluid" src={require('../assets/images/demo/single-post.jpg')} alt="single-post-preview" />
                    </Link>
                  </div>
                  <h4>Single Post</h4>
                </div>
                {/* CONTACTS PAGE */}
                <div className="col-md-6 col-lg-4 select_link wow fadeInUp" data-wow-delay="2.1s">
                  <div className="hover-overlay">
                    <Link to="/contacts">
                      <img className="img-fluid" src={require('../assets/images/demo/contacts.jpg')} alt="contacts-preview" />
                    </Link>
                  </div>
                  <h4>Contacts Page</h4>
                </div>
                {/* EMPTY */}
                <div className="col-md-6 col-lg-4 select_link empty_link wow fadeInUp" data-wow-delay="2.3s">
                  <div className="hover-overlay">
                    <img className="img-fluid" src={require('../assets/images/demo/empty.png')} alt="empty" />
                  </div>
                  <h4>Coming Soon...</h4>
                </div>                
              </div>
            </div>
          </section>

          {/* SECTION DIVIDER */}
          <div className="divider-wrapper text-center"><div className="section-divider" /></div>

          {/* BANNER-1 */}
          <div id="banner-1" className="banner-section division">
            <div className="container">
              <div className="row d-flex align-items-center">
                <div className="col-lg-6">
                  <div className="banner-1-txt mb-40">
                    <span className="section-id">Perfect Integration</span>
                    <h2>Create your awesome website with Descode</h2>
                    <p>Descode includes well designed components which always gives you best results as you wanted.
                      Each content element has been crafted with terrific attention to details and offers multiple options.
                      Combine the content elements to create unique websites in minutes. You are free to move the blocks between demos
                    </p>
                    <Link className="btn btn-rose tra-rose-hover" to="/" >See All Pages In Action</Link>
                  </div>
                </div>
                {/* IMAGE */}
                <div className="col-lg-6">
                  <div className="banner-1-img mb-40">
                    <img className="img-fluid" src={require('../assets/images/demo/banner-1-img.png')} alt="banner" />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default Demo; 