import React from 'react';
import { Link } from "react-router-dom";
import '../assets/css/menu.css';
import '../assets/js/menu.js';

class Header extends React.Component {
    render() {
        return (
            <>
                <header id="header" className="header white-menu navbar-dark">
                    <div className="header-wrapper">
                        <div className="wsmobileheader clearfix">
                            {/* <Link to="/" className="smllogo"><img src={require('../assets/images/logo/logo.png')} alt="mobile-logo" /></Link>
                            <Link to="#" id="wsnavtoggle" className="wsanimated-arrow"><span></span></Link> */}
                            <a className="wsmenumobilelink" href="https://twitter.com/instant_notion"><span className={`flaticon-twitter`} /></a>
                        </div>
                        <div className="wsmainfull menu clearfix">
                            <div className="wsmainwp clearfix">
                                {/* <div className="desktoplogo">
                                    <Link to="/" className="logo-black"><img src={require('../assets/images/logo/logo.png')} alt="header-logo" /></Link>
                                </div>
                                <div className="desktoplogo">
                                    <Link to="/" className="logo-white"><img src={require('../assets/images/logo/logo-white.png')} alt="header-logo" /></Link>
                                </div> */}
                                <nav className="wsmenu clearfix">
                                    <ul className="wsmenu-list nav-rose-hover">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="/">English</a></li>
                                        <li><a href="/jp">日本語</a></li>
                                        <li><a href="https://michalcreates.substack.com">Newsletter</a></li>
                                        <li><a href="https://twitter.com/instant_notion">Twitter</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        )
    }
}
export default Header