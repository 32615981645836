import React from 'react';

//*** COMPONET PROPS INFO: ***//
// (1) section_bg_class =  Background Like this class "bg_chat | bg_fit_01 | bg_fit_02 | bg_mobile | bg_bank_01 | bg_bank_02 | bg_purple_img | bg_sblue_img | bg_selfy | bg_pattern_01 | bg_pattern_02 | bg_pattern_03"
//                         ADD extra class like pt-1/100, pb-1/100, pl-1/100, pr-1/100 and "Your Own Class".
// (2) font_color =  white-color | dark-color | grey-color | lightgrey-color | blue-color | green-color | purple-color | rose-color | sapphire-color | skyblue-color | stateblue-color | violet-color | yellow-color
// (3) sec_img = section left sided image
// (4) sub_title = Banner small title
// (5) title = Banner title
// (6) content = Banner Content

const InfoBannerLeft = (props) => {
    return (
        <>
            <section className="pb-80 content-section">
                <div className={`bg-inner ${!props.section_bg_class == '' ? props.section_bg_class : 'blue_gradient'} division pt-100 ${props.sec_img == undefined ? 'pb-80' : ''}`}>
                    <div className={`container ${!props.font_color == '' ? props.font_color : ''}`}>
                        <div className="row d-flex align-items-center">
                            {
                                !props.sec_img == '' ?
                                    <div className="col-md-5 col-lg-6">
                                        <div className="content-5-img right-column pc-20 text-center" >
                                            <img className="img-fluid" src={require(`../assets/images/${props.sec_img}`)} alt="content" />
                                        </div>
                                    </div>
                                    : ''
                            }
                            <div className={`${props.sec_img == undefined ? 'col-12 text-center' : 'col-md-7 col-lg-6 '}`}>
                                <div className="txt-block left-column mb-40">
                                    {/* Sub Title */}
                                    {
                                        !props.sub_title == '' ? <span className={`section-id D`}>{props.sub_title}</span> : ''
                                    }

                                    {/* Title */}
                                    {
                                        !props.title == '' ? <h2 className={`h2-md`}>{props.title}</h2> : ''
                                    }

                                    {/* Description */}
                                    {
                                        !props.content == '' ? <p className={`p-lg`}>{props.content}</p> : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default InfoBannerLeft;
