import React from 'react';
import Blog_Data from "../assets/json/Blog_Data";
import Section_Title from './Section_Title';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Theme classes like("pt-100 | mb-100 & Background class find in style.css") & your own class
// (2) sec_title = Section title
// (3) sec_description = Section description
// (4) Blog_Data =  All blog data come here on JSON file (assets/json/Blog_Data.json)
//                  (1) item.image = Blog image
//                  (2) item.catagory = Blog catagory
//                  (3) item.title = Blog title
//                  (4) item.content = Blog content
//                  (5) item.authorImg = Blog author image
//                  (6) item.write = Blog writing time

const Blog = (props) => {

    return (
        <>
            <section id="blog-1" className={`wide-60 blog-section division ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className="container">
                    {/* <div className="row">
                        <div className="col-12">
                            <Section_Title
                                title={props.sec_title}
                                description={props.sec_description}
                            />
                        </div>
                    </div> */}
                    <div className="row">

                        {/* START: Json file data loop*/}
                        {Blog_Data.slice(props.itemStart, props.itemEnd).map((item, index) =>
                            <div className="col-md-6 col-lg-4" key={'blogKey_' + index}>
                                <a href={item.link}>
                                    <div className="blog-post mb-40">
                                        {/* blog image */}
                                        {
                                            // !item.image == '' || false
                                            //     ?
                                            //     <div className="blog-post-img">
                                            //         <img className="img-fluid" src={require('../assets/images/blog/' + item.image)} alt="blog-post" />
                                            //     </div>
                                            //     :
                                            ''
                                        }
                                        <div className="blog-post-txt">
                                            {/* blog catagory */}
                                            {!item.catagory == '' ? <p className="p-md post-tag">{item.catagory}</p> : ''}
                                            {/* blog title */}
                                            {!item.title == '' ? <h5 className="h5-sm">{item.title}</h5> : ''}
                                            {/* blog content */}
                                            {!item.content == '' ? <p className="p-md">{item.content}</p> : ''}

                                            <div className="post-meta">
                                                {/* blog author image */}
                                                {
                                                    !item.authorImg == ''
                                                        ?
                                                        <div className="post-author-avatar">
                                                            <img src={require('../assets/images/blog/' + item.authorImg)} alt="author-avatar" />
                                                        </div>
                                                        :
                                                        ''
                                                }
                                                {/* blog write */}
                                                {!item.write == '' ? <p>{item.write}</p> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )}
                        {/* END: Json file data loop*/}

                    </div>
                </div>
            </section>
        </>
    );
}

export default Blog;
