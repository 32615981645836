import React from 'react';
import CountUp from 'react-countup';
import Section_Title from './Section_Title';
import TrackVisibility from 'react-on-screen';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Background Like this class "bg_chat | bg_fit_01 | bg_fit_02 | bg_mobile | bg_bank_01 | bg_bank_02 | bg_purple_img | bg_sblue_img | bg_selfy | bg_pattern_01 | bg_pattern_02 | bg_pattern_03"
//                      ADD extra class like pt-1/100, pb-1/100, pl-1/100, pr-1/100 and "Your Own Class".
//                      Section font color like(white-color | dark-color | grey-color | lightgrey-color | blue-color | green-color | purple-color | rose-color | sapphire-color | skyblue-color | stateblue-color | violet-color | yellow-color)
// (2) sec_title = section title
// (3) sec_description= section description
// (4) Counter_data = Counter Number and Counter Title Arry
//     Ex:- Counter_data ={
//                           [
//                              {
//                                 conuter_number : 50,
//                                 counter_title: 'Counter title' 
//                              },
//                              {
//                                 conuter_number : 50,
//                                 counter_title: 'Counter title' 
//                              }
//                           ]
//                         }
// item.conuter_number = Counter Number
// item.counter_title = Counter Title
// (5) countText_color = Counter Number Font Color Like (white-color | dark-color | grey-color | lightgrey-color | blue-color | green-color | purple-color | rose-color | sapphire-color | skyblue-color | stateblue-color | violet-color | yellow-color)

const CounterSection = (props) => {
    return (
        <>
            {/* STATISTIC-2 */}
            <div id="statistic-2" className={`statistic-section division ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <Section_Title
                                title={props.sec_title}
                                description={props.sec_description}
                            />
                        </div>
                    </div>
                    {/* STATISTIC-2 WRAPPER */}
                    <div className="statistic-2-wrapper pc-35 text-center">
                        <div className="row">
                            {/* START: Counter Loop */}
                            {
                                !props.Counter_data == ''
                                    ?
                                    props.Counter_data.map((item, index) => {
                                        return (
                                            <div id="sb-2-1" className="col" key={'counter_' + index + 1}>
                                                <div className="statistic-block mb-40">
                                                    {/* Counter Number */}
                                                    {
                                                        !item.counter_number == ''
                                                            ?
                                                            <TrackVisibility>
                                                                {({ isVisible }) => isVisible &&
                                                                    <h2 className={`h2-title-sm statistic-number ${!props.countText_color == '' ? props.countText_color : ''}`}>
                                                                        <span className="count-element">
                                                                            <CountUp className="counter" end={item.counter_number} />

                                                                        </span>
                                                                    </h2>
                                                                }
                                                            </TrackVisibility>
                                                            : ''
                                                    }
                                                    {/* Counter Title */}
                                                    {!item.counter_title == '' ? <h5 className="h5-xs">{item.counter_title}</h5> : ''}
                                                </div>
                                            </div>
                                        )
                                    })
                                    : ''
                            }
                            {/* END: Counter Loop */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CounterSection;
