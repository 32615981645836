import React from 'react';
import WOW from "wowjs";
import Banner1 from '../components/main-banner/Banner1';
import Banner2 from '../components/main-banner/Banner2';
import Banner3 from '../components/main-banner/Banner3';
import Banner4 from '../components/main-banner/Banner4';
import Banner5 from '../components/main-banner/Banner5';
import Banner6 from '../components/main-banner/Banner6';
import Banner7 from '../components/main-banner/Banner7';
import Banner8 from '../components/main-banner/Banner8';
import Banner9 from '../components/main-banner/Banner9';
import Banner10 from '../components/main-banner/Banner10';
import Features_Info from '../components/Features_Info';
import Info_Img_Content from '../components/Info_Img_Content';
import Info_Content_Img from '../components/Info_Content_Img';
import InfoBannerLeft from '../components/InfoBanner_left';
import BrandSlider from '../components/Brand-Slider';
import Accessible_All_Platform_Banner from '../components/Accessible_All_Platform_Banner';
import Download_bgImage_Banner from '../components/banner/Download_bgImage_Banner';
import Pricing from '../components/Pricing';
import Reviews from '../components/Reviews';
import Faqs from '../components/Faqs';
import NewsLetter from '../components/NewsLetter';
import Blog from '../components/Blog';
import Download_bgPattern_Banner_Center from '../components/banner/Download_bgPattern_Banner_Center';
import CounterSection from '../components/Counter_Section';

class Home5Jp extends React.Component {
  componentDidMount() {
    const wow = new WOW.WOW(
      { live: false }
    );
    wow.init();
  }
  render() {
    return (
      <>
        {/* ページの内容 */}
        <div id="page" className="page">

          {/* メインバナー5 */}
          <Banner5
            // すべてのプロパティ情報は "components/main-banner/Banner1.jsx" に設定されています
            section_class={`bg_pattern_03`}
            // 左側のコンテンツ
            lft_title={`インスタントノーション`}
            lft_description={`Notionへのタグ付きノートを保存する最速の方法。6400人以上のユーザーに信頼されています。iOSアプリとして利用可能（Android版も近日登場予定）。`}
            // lft_button_content={`概要を見る`}
            // lft_button_link={``}
            // lft_button_icon={`flaticon-play-button`}

            // 中央のコンテンツ画像
            banner_content_img={`home/hero-5-img.png`}

            // 右側のコンテンツ
            // rtl_title={`効率的になる`}
            app_store_img={`store/appstore.png`}
            appstore_link={`https://apps.apple.com/app/apple-store/id1639385303?pt=118443195&ct=landingpage&mt=8`} // App Storeのリンク
            // play_store_img={`store/googleplay.png`}
            // playstore_link={``}
            user_review={`92`}

          />

          {/* 特徴情報 */}
          <Features_Info
            // すべてのプロパティ情報は "components/Features_Info.jsx" に設定されています
            layout_style={5}
            section_class={`wide-60`}
            sec_title={``}
            sec_description={``}
            icon_color={`stateblue-color`}
            info_data={[
              {
                icon: `flaticon-lightning`,
                title: `Notionのクイックキャプチャ`,
                description: `Notionはノート用の素晴らしいツールですが、モバイルでは遅すぎることがあります。特定のデータベースを開くのに数秒かかることがあります。クイックノーションでノートを素早くキャプチャできます。`
              },
              {
                icon: `flaticon-wifi`,
                title: `オフラインサポート`,
                description: `エレベーターや地下鉄で素晴らしいアイデアが浮かんだことはありませんか？Instant Notionを使用して、オフラインの間にそれを素早く保存できます。オンラインに戻ったときにNotionに送信されます。`
              },
              {
                icon: `flaticon-shield`,
                title: `セキュア`,
                description: `公式のNotion認証フローを介してアクセス許可を付与します。プライベートAPIキーを生成する必要はありません。あなたのノートは直接あなたの電話からNotionに移動します。`
              }
            ]}
          />

          {/* <InfoBannerLeft
            // すべてのプロパティ情報は "components/InfoBanner_left.jsx" に設定されています
            section_bg_class={`bg_white`}
            font_color={``}
            sec_img={`home/img-04.png`}
            // sub_title={`最速のメッセージング`}
            title={`オフラインですか？問題ありません！`}
            content={`Notionは素晴らしいですが、残念ながらオフラインの状況ではうまく機能しません。インスタントノーションを使用すれば、インターネットに接続していない状態でもノートを保存できます。`}
          /> */}
          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // すべてのプロパティ情報は "Info_Content_Img.Jsx" に設定されています
            section_class={`pb-60`}
            sub_title={`プロの機能`}
            title={`ホーム画面のNotionウィジェットにタグを追加`}
            p_content_data={
              [
                `ノートを保存するのにかかる時間を数秒に短縮します。`,
                `プロユーザーは、ノートをさらに早く書き始めるために、ホーム画面にタグのリストを持つウィジェットを追加できます。`
              ]
            }
            icon_color={'stateblue-color'}
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            rtl_image={`home/img-07.png`}
            img_Class={``}
            bgShape_design_1={'bg_el_05'}
            bgShape_design_2={''}
          />

          <InfoBannerLeft
            // すべてのプロパティ情報は "components/InfoBanner_left.jsx" に設定されています
            section_bg_class={`bg_white`}
            font_color={``}
            sec_img={`home/img-15.png`}
            // sub_title={`最速のメッセージング`}
            title={`複数のデータベースをサポート！`}
            content={`安全に複数のデータベースをInstant Notionに添付できます（好きなだけ）。2回のタップでデータベースを切り替えます（オフラインでも可能！）。`}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          {/* <Info_Img_Content
            // すべてのプロパティ情報は "Info_Img_Content.Jsx" に設定されています
            section_class={`pb-100`}
            lft_image={`home/img-02.png`}
            img_Class={``}
            sub_title={`完璧な統合`}
            title={`高速でパワフル`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus and egestas sapien egestas vitae nemo volute`,
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          /> */}

          {/* 特徴情報 */}
          {/* <Features_Info
            // すべてのプロパティ情報は "components/Features_Info.jsx" に設定されています
            layout_style={8}
            section_class={`wide-100 bg_whitesmoke `}
            sec_title={`Descodeを試す準備はできましたか？`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            icon_color={`white-color bg_slateblue`}
            info_data={[
              {
                icon: `flaticon-user-1`,
                title: `複数のアカウント`,
                description: `Porta semper lacus a cursus, feugiat primis ultrice ligula and risus auctor ultrice and ociis ligula blandit`
              },
              {
                icon: `flaticon-smiley`,
                title: `ステッカーと絵文字`,
                description: `Porta semper lacus a cursus, feugiat primis ultrice ligula and risus auctor ultrice and ociis ligula blandit`
              },
              {
                icon: `flaticon-image`,
                title: `ファイルとメディアの共有`,
                description: `Porta semper lacus a cursus, feugiat primis ultrice ligula and risus auctor ultrice and ociis ligula blandit`
              }
            ]}
          /> */}

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          {/* <Info_Content_Img
            // すべてのプロパティ情報は "Info_Content_Img.Jsx" に設定されています
            section_class={`wide-100`}
            sub_title={``}
            title={``}
            icon_content_data={
              [
                {
                  icon: `flaticon-video-player`,
                  title: `テキスト、音声、ビデオ通話`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                },
                {
                  icon: `flaticon-smiley`,
                  title: `ステッカー、絵文字、テーマ`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                },
                {
                  icon: `flaticon-image`,
                  title: `ファイルとメディアの共有`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                }
              ]
            }
            icon_color={'stateblue-color'}
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            rtl_image={`home/img-05.png`}
            img_Class={``}
            bgShape_design_1={'bg_el_01'}
            bgShape_design_2={'bg_el_04'}
          />
 */}

          {/* 特徴情報*/}
          {/* <Features_Info
            // すべてのプロパティ情報は "components/Features_Info.jsx" に設定されています
            layout_style={3}
            section_class={`pb-100`}
            sec_title={`期待されるさらなる機能`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            info_data={
              [
                {
                  title: `ダークモードとライトモード`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-paint-brush`
                },
                {
                  title: `グループメッセージング`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-smartphone-8`
                },
                {
                  title: `音声アシスタント`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-voice-message-1`
                },
                {
                  title: `強力な暗号化`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-mobile-1`
                }
              ]
            }
            icon_color={''}
          /> */}

          {/* カウンターセクション */}
          <CounterSection
            // すべてのプロパティ情報は "components/Counter_Section.jsx" に設定されています	
            section_class={`pt-70 pb-40 bg_purple_img white-color`}
            sec_title={``}
            sec_description={``}
            Counter_data={
              [
                {
                  counter_number: 6429,
                  counter_title: 'ユーザー'
                },
                {
                  counter_number: 53421,
                  counter_title: '保存されたメモ'
                }
                // {
                //   counter_number: 70,
                //   counter_title: 'App Store ratings'
                // },
              ]
            }
            countText_color={``}
          />
          {/* 

          <Info_Img_Content
            // すべてのプロパティ情報は "Info_Img_Content.Jsx" に設定されています
            section_class={`wide-100`}
            lft_image={`home/img-03.png`}
            img_Class={``}
            sub_title={`美の単純さ`}
            title={`美しい、賞-winningなデザイン`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          <Info_Content_Img
            // すべてのプロパティ情報は "Info_Content_Img.Jsx" に設定されています
            section_class={`pb-60`}
            sub_title={`Totally Optimized`}
            title={`Intuitive features, powerful results`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`
              ]
            }
            icon_color={''}
            button={true}
            button_content={`Read The FAQs`}
            button_bg_and_hover={`btn-tra-grey rose-hover`}
            button_icon={``}
            rtl_image={`home/tablet.png`}
            img_Class={`content-13-img`}
            bgShape_design_1={'bg_el_01'}
            bgShape_design_2={'bg_el_05'}
          />

          <BrandSlider
            // すべてのプロパティ情報は "Brand-Slider.Jsx" に設定されています
            section_class={`bg_whitesmoke`}
            sec_title={`You might know Descode from:`}
            brand_images={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          />

          <Accessible_All_Platform_Banner
            // すべてのプロパティ情報は "components/Accessible_All_Platform_Banner.jsx" に設定されています
            section_class={`wide-100`}
            sec_title={`すべてのプラットフォームでアクセス可能`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            sec_img={`home/browsers.png`}
            button={true} // true/false
            button_content={`Descodeをご覧ください `}
            button_bg_and_hover={`btn-tra-grey rose-hover`} // btn-tra-grey skyblue-hover
            button_icon={`flaticon-play-button`} // flaticon-play-button
            button_description={`Android、iOS、macOSで利用可能`}
          />

          <Download_bgImage_Banner
            // すべてのプロパティ情報は "components/Download_bg_img.jsx" に設定されています		
            section_class={'bg_chat'}
            font_color={`white-color`}
            sub_title={'Speedに特化'}
            title={'無料でテキスト、音声、写真、ビデオ、GIF、ファイルを共有'}
            description={`Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit posuere orci auctor purus euismod an aliquam quaerat purus`}
            img1={'store/appstore.png'}
            img2={'store/googleplay.png'}
          />

          <Pricing
            // すべてのプロパティ情報は "components/Pricing.jsx" に設定されています	
            section_class={`wide-100 `}
            sec_title={`シンプルで柔軟な価格設定`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
          /> */}

          {/* REVIEWS SIMPLE */}
          <Reviews
            // すべてのプロパティ情報は "components/Reviews.jsx" に設定されています
            section_class={`bg_aliceblue wide-60`}
            section_title={`ユーザーからのInstant Notionの評判`}
            // section_description={`With average rating being `}
            app_store_img={`store/appstore.png`}
            appstore_link={`https://apps.apple.com/app/apple-store/id1639385303?pt=118443195&ct=landingpage&mt=8`}
          // play_store_img={`store/googleplay.png`}
          // playstore_link={``}
          // app_version_desc={`Available for iOS 8 and Android Devices From 5.5`}
          />

          {/* FAQS */}
          <Faqs
            // すべてのプロパティ情報は "components/Faqs.jsx" に設定されています
            section_class={``}
            sub_title={`FAQs`}
            title={`よくある質問`}
            content={``}
          />

          {/* <NewsLetter
            // すべてのプロパティ情報は "components/NewsLetter.jsx" に設定されています
            section_class={`bg_whitesmoke wide-60`}
            sec_title={`Never Miss an Update`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, dolor at and cursus diam commodo tortor. Donec et leo aliquam dolor`
          /> */}

          <Blog
            // すべてのプロパティ情報は "components/Blog.jsx" に設定されています
            section_class={`pb-60 pt-60`}
            sub_title={`ブログ`}
            title={`最新の記事`}
            content={``}
          />

          {/* INFO-BG-PATTERN-1 */}
          {/* <Download_bgPattern_Banner_Center
            // すべてのプロパティ情報は "components/banner/Download_bgPattern_Banner_Center.jsx" に設定されています
            section_class={`pt-70 pb-70`}
            sec_title={`カスタムデザイン`}
            title={`一瞬でノートをキャプチャして保存`}
            description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, dolor at and cursus diam commodo tortor. Donec et leo aliquam dolor`}
            img1={'store/appstore.png'}
            img2={'store/googleplay.png'}
          /> */}

        </div>
      </>
    )
  }
}

export default Home5Jp;
