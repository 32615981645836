
import * as React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Loader from "./components/Loader";
import Header from './components/Header';
import Demo from './pages/Demo';
import Home from './pages/Home';
import Home2 from './pages/Home2';
import Home3 from './pages/Home3';
import Home4 from './pages/Home4';
import Home5 from './pages/Home5';
import Home6 from './pages/Home6';
import Home7 from './pages/Home7';
import Home8 from './pages/Home8';
import Home9 from './pages/Home9';
import Home10 from './pages/Home10';
import MoreAppsPage from './pages/MoreAppsPage';
import Pricing from './pages/Pricing';
import Reviews from './pages/Reviews';
import Faqs from './pages/Faqs';
import BlogListing from './pages/BlogListing';
import FastNotionCapture from './pages/FastNotionCapture';
import HowToTakeGoodNotes from './pages/HowToTakeGoodNotes';
import Terms from './pages/Terms';
import Contacts from './pages/Contacts';
import Features from './pages/Features';
import Footer from './components/Footer';
import Error from './pages/Error';
import './assets/css/bootstrap.min.css';
import './assets/css/flaticon.css';
import './assets/css/flaticon_instant_notion_web.css';
import './assets/css/animate.css';
import './assets/css/dropdown-effects/fade-down.css';
import './assets/css/typography.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/js/custom.js';
import HowToTakeGoodNotesJp from "./pages/HowToTakeGoodNotes_jp";
import FastNotionCaptureJp from "./pages/FastNotionCapture_jp";
import Home5Jp from "./pages/Home5Jp";
import Impressum from "./pages/Impressum.jsx";

//*** Add "homepage" in package.json ***//

//Ex:- "homepage": "https://codextheme.codexperts.in/descode"   ||   "homepage": "https://www.yourdomain.com/folder/descode"
//Ex:- <BrowserRouter basename='/descode/'>   ||   <BrowserRouter basename='/react/descode/'>

//*** basename = domain name after folder path. ***//
// Ex:- basename='/descode/'

const App = () => {
  return (
    <>
      <BrowserRouter basename='/'>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home5 />} />
          <Route exact path="/jp" element={<Home5Jp />} />
          <Route exact path="/impressum" element={<Impressum />} />
          <Route exact path="/articles/fast-notion-note-capture" element={<FastNotionCapture />} />
          <Route exact path="/articles/fast-notion-note-capture-jp" element={<FastNotionCaptureJp />} />
          <Route exact path="/articles/how-to-take-good-notes" element={<HowToTakeGoodNotes />} />
          <Route exact path="/articles/how-to-take-good-notes-jp" element={<HowToTakeGoodNotesJp />} />
          <Route exact path="*" element={<Home5 />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
