import React from 'react';

class Impressum extends React.Component {
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">

          {/* SINGLE POST */}
          <section id="single-post" className="bg_whitesmoke hero-offset-nav pb-80 single-post-section division">
            <div className="container">
              {/* SINGLE POST CONTENT */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="single-post-wrapper">
                    {/* SINGLE POST TITLE */}
                    <div className="single-post-title">
                      {/* CATEGORY */}
                      <p className="p-lg post-tag skyblue-color">Impressum</p>
                      {/* TITLE */}
                      <h4 className="h4-xl">Impressum</h4>
                      {/* POST DATA */}
                      <div className="post-data clearfix">

                      </div>
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="single-post-txt">
                      {/* Text */}
                      <p className="p-lg">
                        Michał Żołnieruk
                        Sprengelstr. 18, Berlin 13353
                        E-Mail: michalzolnieruk@gmail.com
                        USt-IDNR.: DE337861905</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* GEOMETRIC OVERLAY */}
            <div className="bg_fixed geometric_overlay" />
          </section>

        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default Impressum;