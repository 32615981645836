import React, { useState, useEffect } from 'react';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Theme classes like("wide-100 | pt-100 | mb-100 & Background class find in style.css") & your own class
// (2) sub_title = Section right side sub title
// (3) title = Section right side title
// (4) input_placeholder = Your place holder on input box
// (5) button_bg_and_hover = Button background & on hover background like this("avocado-hover button_bg_and_hover") [Other bg classes find in style.css "Button Color"]
// (6) btn_content = Button content

const NewsLetter = (props) => {

    const [newLetter_data, setNewsLetter_data] = useState([]);
    let [localNewLetter_data, setLocalNewLetter_data] = useState()

    // form submit
    const submitData = (e) => {
        e.preventDefault();
        setLocalNewLetter_data(newLetter_data)
        localStorage.setItem('newsLetter_data', JSON.stringify(newLetter_data));

        // "newLetter_data" data store this variable
        //alert(newLetter_data)
        alert("Submit successfully !");
    }

    return (
        <>
            <section id="newsletter-1" className={`newsletter-section division ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className="container">
                    <div className="newsletter-wrapper bg_white">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-6">
                                <div className="newsletter-txt">
                                    {/* sub title */}
                                    {!props.sub_title == '' ? <span className="section-id">{props.sub_title}</span> : ''}
                                    {/* title */}
                                    {!props.title == '' ? <h4 className="h4-xl">{props.title}</h4> : ''}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <form className="newsletter-form" onSubmit={(e) => submitData(e)}>
                                    <div className="input-group">
                                        <input type="email" onChange={(e) => setNewsLetter_data(e.target.value)} className="form-control" placeholder={`${!props.input_placeholder == '' ? props.input_placeholder : 'Your email address'}`} required id="s-email" />

                                        {/* button */}
                                        {!props.btn_content == ''
                                            ?
                                            <span className="input-group-btn">
                                                <button type="submit" className={`btn btn-md ${!props.button_bg_and_hover == '' ? props.button_bg_and_hover : 'btn-skyblue tra-grey-hover'}`}>{props.btn_content}</button>
                                            </span>
                                            : ''
                                        }
                                    </div>
                                    <label htmlFor="s-email" className="form-notification" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NewsLetter;
