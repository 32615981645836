import React from 'react';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  --Background Like this class "bg_pattern_01 | bg_pattern_02 | bg_pattern_03" | bg_purple_img | bg_sblue_img | bg_selfy | blue_gradient. 
//                         Other options find in style.css(Background Colors && Background Gradients && Background Images for Sections) 
//                      --ADD extra class like (pt-1/100, pb-1/100, pl-1/100, pr-1/100, "Your Own Class").

// (2) font_color = Content font color like (white-color | dark-color | grey-color | lightgrey-color | blue-color | green-color | purple-color | rose-color | sapphire-color | skyblue-color | stateblue-color | violet-color | yellow-color)
// (3) banner_title = Banner title
// (4) app_store_img = App store image
// (5) appstore_link = App store link
// (6) play_store_img = Play store image
// (7) playstore_link = play store link
// (8) info_description = version description
// (9) banner_content_img = Banner side section image

const Banner4 = (props) => {
    return (
        <>
            <section id="hero-4" className={`hero-section  ${!props.section_class == '' ? props.section_class : ''}`}>
                {/* HERO TEXT */}
                <div className="bg_scroll hero-4-txt division">
                    <div className={`container ${!props.font_color == '' ? props.font_color : ''}`}>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 text-center">

                                {/* Banner Title  */}
                                {!props.banner_title == '' ? <h2 className="h2-sm">{props.banner_title}</h2> : ''}

                                <div className="stores-badge mb-10">
                                    {/* App store image */}
                                    {
                                        !props.app_store_img == ''
                                            ?
                                            <a href={`${!props.appstore_link == '' ? props.appstore_link : '#/'}`} className="store">
                                                <img className="appstore" src={require(`../../assets/images/${props.app_store_img}`)} alt="appstore-badge" />
                                            </a>
                                            : ''
                                    }

                                    {/* Google Play store image */}
                                    {
                                        !props.play_store_img == ''
                                            ?
                                            <a href={`${!props.playstore_link == '' ? props.playstore_link : '#/'}`} className="store">
                                                <img className="googleplay" src={require(`../../assets/images/${props.play_store_img}`)} alt="googleplay-badge" />
                                            </a>
                                            : ''
                                    }
                                </div>
                                {!props.info_description == '' ? <span className="os-version">{props.info_description}</span> : ''}
                            </div>
                        </div>
                    </div>
                </div>                

                {/* Banner side sec. image */}
                {
                    !props.banner_content_img == ''
                        ?
                        <div className="hero-4-img division">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <img className="img-fluid" src={require(`../../assets/images/${props.banner_content_img}`)} alt="hero" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''
                }
            </section>
        </>
    );
}

export default Banner4;
