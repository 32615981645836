import React from 'react';
class FastNotionCaptureJp extends React.Component {
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">

          {/* SINGLE POST */}
          <section id="single-post" className="bg_whitesmoke hero-offset-nav pb-80 single-post-section division">
            <div className="container">
              {/* SINGLE POST CONTENT */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="single-post-wrapper">
                    {/* SINGLE POST TITLE */}
                    <div className="single-post-title">
                      {/* CATEGORY */}
                      <p className="p-lg post-tag skyblue-color">Instant Notion articles</p>
                      {/* TITLE */}
                      <h4 className="h4-xl">インスタントノーションiOSアプリのご紹介：簡単にノートをノーションにキャプチャ</h4>
                      {/* POST DATA */}
                      <div className="post-data clearfix">

                      </div>
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="single-post-txt">
                      {/* Text */}
                      <p className="p-lg">インスタントノーションは、Notionワークスペースへのクイックノートキャプチャの究極のiOSアプリです。インスタントノーションを使用すると、思考を簡単にキャプチャして整理し、貴重な時間と労力を節約できます。アプリ間の切り替えの手間を省き、ノートの取り方を合理化しましょう。</p>
                    </div>
                    {/* BLOG POST INNER IMAGE */}
                    <a href={`https://apps.apple.com/app/apple-store/id1639385303?pt=118443195&ct=landingpage&mt=8'}`}>
                      <div className="post-inner-img">
                        <img className="img-fluid" src={require('../assets/images/blog/inner-img-1.jpg')} alt="blog-post" />
                      </div>
                    </a>
                    {/* BLOG POST TEXT */}
                    <div className="single-post-txt">
                      {/* Text */}
                      <p className="p-lg">インスタントノーションには、ノート取りのエクスペリエンスを向上させるための強力な機能が満載です：</p>
                      {/* List */}
                      <ul className="digit-list">
                        <li><p className="p-lg">ロックスクリーンウィジェットでアプリを瞬時に起動し、ノートを素早く効率的にキャプチャできます。</p></li>
                        <li><p className="p-lg">Notionデータベースのタグを利用して、ノートを簡単にタグ付けし、一貫性と整理を保ちます。</p></li>
                        <li><p className="p-lg">インターネットに接続していない場合でもノートをオフラインで保存できます。ノートはオンラインに戻った後、Notionに送信されます</p></li>
                      </ul>
                      {/* Text */}
                      <p className="p-lg">インスタントノーションは、iPhoneでノーションにノートをキャプチャして保存する方法を革命的に変えます。アプリを今すぐダウンロードして、速くて効率的なノート取りを体験してください。Fast Notionが現実になるかもしれません、今すぐ試してみてください。</p>
                      <div className="stores-badge mb-5">
                        {/* app store image */}
                        {
                          <a href={`https://apps.apple.com/app/apple-store/id1639385303?pt=118443195&ct=landingpage&mt=8'}`} className="store">
                            <img className="appstore-original" src={require(`../assets/images/store/appstore.png`)} alt="appstore-badge" />
                          </a>
                        }
                      </div>
                    </div>

                    <div className="other-posts">
                      <div id="op-row" className="row d-flex align-items-center">
                        {/* Previous Post */}
                        <div className="col-md-5">
                          <div className="prev-post mb-30 pr-45">
                            <h5 className="h5-sm">次の記事</h5>
                            <a href="/articles/how-to-take-good-notes">良いノートを取る方法</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* GEOMETRIC OVERLAY */}
            <div className="bg_fixed geometric_overlay" />
          </section>

        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default FastNotionCaptureJp;