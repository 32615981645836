import React from 'react';

//*** COMPONET PROPS INFO: ***//
// (1) title_class =  Custom class
// (2) sub_title 
// (3) title
// (4) description

const Section_Title = (props) => {
    return (
        <>             
            <div className={`section-title ${!props.title == '' ? 'mb-70' : 'mb-0'} ${!props.title_class == '' ? props.title_class : 'text-center'}`}>
                {!props.sub_title == '' ? <span className="section-id">{props.sub_title}</span> : ''}
                {!props.title == '' ? <h2 className="h2-md">{props.title}</h2> : ''}
                {!props.description == '' ? <p className="p-xl">{props.description}</p> : ''}
            </div>
        </>
    );
}

export default Section_Title;
