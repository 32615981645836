import React from 'react';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  --Background Like this class "bg_pattern_01 | bg_pattern_02 | bg_pattern_03" | bg_purple_img | bg_sblue_img | bg_selfy | blue_gradient. 
//                         Other options find in style.css(Background Colors && Background Gradients && Background Images for Sections) 
//                      --ADD extra class like (pt-1/100, pb-1/100, pl-1/100, pr-1/100, "Your Own Class").

// (2) font_color = Content font color like (white-color | dark-color | grey-color | lightgrey-color | blue-color | green-color | purple-color | rose-color | sapphire-color | skyblue-color | stateblue-color | violet-color | yellow-color)
// (3) banner_image = Banner left image
// (4) banner_image_btn_link = App Link
// (45 banner_title = Banner title
// (6) banner_description = Banner discription
// (6) app_store_img = App store image
// (7) appstore_link = App store link
// (8) play_store_img = Play store image
// (9) playstore_link = play store link
// (10) version_desciption = app version content

const Banner7 = (props) => {
    return (
        <>
            <section id="hero-7" className={`hero-section division ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className={`container ${!props.font_color == '' ? props.font_color : ''}`}>
                    <div className="row d-flex align-items-center">
                        {/* Banner Main Image */}
                        {
                            !props.banner_image == ""
                                ?
                                <div className="col-md-5 col-lg-6">
                                    <div className="hero-7-img pc-15 text-center mb-40">
                                        <img className="img-fluid" src={require(`../../assets/images/${props.banner_image}`)} alt="hero" />
                                    </div>
                                </div>

                                : ''
                        }
                        {/* HERO TEXT */}
                        <div className={`${!props.banner_image == '' ? 'col-md-7 col-lg-6' : 'col-12 text-center'}`}>
                            <div className="hero-7-txt pc-35 mb-40">

                                {/* Banner Title  */}
                                {!props.banner_title == '' ? <h2 className="h2-xl">{props.banner_title}</h2> : ''}

                                {/* Banner Description */}
                                {!props.banner_description == '' ? <p className="p-xl grey-color">{props.banner_description}</p> : ''}

                                {/* Store Badges */}
                                <div className="stores-badge mb-20">
                                    {/* App store image */}
                                    {
                                        !props.app_store_img == ''
                                            ?
                                            <a href={`${!props.appstore_link == '' ? props.appstore_link : '#/'}`} className="store">
                                                <img className="appstore" src={require(`../../assets/images/${props.app_store_img}`)} alt="appstore-badge" />
                                            </a>
                                            : ''
                                    }

                                    {/* Play store image */}
                                    {
                                        !props.play_store_img == ''
                                            ?
                                            <a href={`${!props.playstore_link == '' ? props.playstore_link : '#/'}`} className="store">
                                                <img className="googleplay" src={require(`../../assets/images/${props.play_store_img}`)} alt="googleplay-badge" />
                                            </a>
                                            : ''
                                    }
                                </div>
                                
                                {/* version description */}
                                {!props.version_desciption == '' ? <span className="os-version grey-color">{props.version_desciption}</span> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Banner7;
