import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Background Like this class "bg_chat | bg_fit_01 | bg_fit_02 | bg_mobile | bg_bank_01 | bg_bank_02 | bg_purple_img | bg_sblue_img | bg_selfy | bg_pattern_01 | bg_pattern_02 | bg_pattern_03"
//                         ADD extra class like pt-1/100, pb-1/100, pl-1/100, pr-1/100 and "Your Own Class".
// (2) sec_title = section title
// (3) brand_images = set image name set [brand-"1" to brand-"*"]

const BrandSlider = (props) => {
  var brands = {
    dots: false,
    infinite: true,
    arrows:false,    
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 10000,
    pauseOnHover: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow:3,
        }
      },
    ]
  };
  return (
    <>
      <section id="brands-1" className={`wide-70 brands-section division ${!props.section_class == '' ? props.section_class : ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="brands-title text-center">
                <p class="p-xl">{!props.sec_title == '' ? props.sec_title : ''}</p>
              </div>
            </div>
          </div>
          <div className="brands-2-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Slider {...brands}>
                  {/* Slider Loop Start */}
                  {
                    !props.brand_images == ''
                      ?
                      props.brand_images.map((item, index) => {
                        return (
                          // Brand-Image
                          <div className="brand-logo" key={'brand_' + index + 1}>
                            <a href="#/">
                              <img className="img-fluid" src={require(`../assets/images/brand/brand-${item}.png`)} alt="brand-logo" />
                            </a>
                          </div>
                        )
                      })
                      : ''
                  }
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default BrandSlider;



