import React from 'react';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  --Background Like this class "bg_pattern_01 | bg_pattern_02 | bg_pattern_03" | bg_purple_img | bg_sblue_img | bg_selfy | blue_gradient. 
//                         Other options find in style.css(Background Colors && Background Gradients && Background Images for Sections) 
//                      --ADD extra class like (pt-1/100, pb-1/100, pl-1/100, pr-1/100, "Your Own Class").

// (2) font_color = Content font color like (white-color | dark-color | grey-color | lightgrey-color | blue-color | green-color | purple-color | rose-color | sapphire-color | skyblue-color | stateblue-color | violet-color | yellow-color)
// (3) banner_app_logo = App logo
// (4) banner_title = Banner title
// (5) banner_description = Banner discription

// (6) button_1 =  Button 1 (true/false)
// (7) button_1_content = Button 1 content
// (8) button_1_bg_and_hover = Button 1 background & hover background like this ("avocado-hover button_bg_and_hover") [Other bg classes find in style.css "Button Color"]
// (9) button_1_link = Button 1 link

// (10) button_2 = Button 2 (true/false)
// (11) button_2_content = Button 2 content
// (12) button_2_bg_and_hover = Button 2 background & hover background like this ("avocado-hover button_bg_and_hover") [Other bg classes find in style.css "Button Color"]
// (13) button_2_link = Button 2 link

// (14) banner_main_img = Banner Main section image

const Banner6 = (props) => {
    return (
        <>
            <section id="hero-6" className={`hero-section division ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className={`container ${!props.font_color == '' ? props.font_color : 'white-color'}`}>
                    <div className="row">
                        <div className={props.banner_main_img == undefined ? 'col-12 text-center' : 'col-lg-7'}>
                            <div className="hero-6-txt pc-25">

                                {/* Title  */}
                                {!props.banner_title == '' ? <h2>{props.banner_title}</h2> : ''}

                                {/* Description */}
                                {!props.banner_description == '' ? <p className="p-xl">{props.banner_description}</p> : ''}

                                {/* Buttons Group */}
                                <div className="btns-group mb-25">
                                    {
                                        !props.button_1 == false
                                            ?
                                            <a href={!props.button_1_link == '' ? props.button_1_link : '#/'} className={`video-popup2 btn btn-md mr-15 ${!props.button_1_bg_and_hover == '' ? props.button_1_bg_and_hover : ''}`}>
                                                {!props.button_1_content == '' ? props.button_1_content : 'Action btn-1'}
                                            </a>
                                            : ''
                                    }
                                    {
                                        !props.button_2 == false
                                            ?
                                            <a href={!props.button_2_link == '' ? props.button_2_link : '#/'} className={`video-popup2 btn btn-md ${!props.button_2_bg_and_hover == '' ? props.button_2_bg_and_hover : ''}`}>
                                                {!props.button_2_content == '' ? props.button_2_content : 'Action btn-2'}
                                            </a>
                                            : ''
                                    }

                                </div>

                                {/* Rating */}
                                <div className="txt-block-rating">
                                    <div className="stars-rating">
                                        Customers Rating
                                        <span className="flaticon-star ml-5" />
                                        <span className="flaticon-star" />
                                        <span className="flaticon-star" />
                                        <span className="flaticon-star" />
                                        <span className="flaticon-star-half-empty" />
                                    </div>
                                    <span className="txt-rating">Based on {!props.user_Count == '' ? props.user_Count : '****'} App Store user reviews</span>
                                </div>
                            </div>
                        </div>

                        {/* Banner Main image */}
                        {
                            !props.banner_main_img == ''
                                ?
                                <div className="col-lg-5">
                                    <div className="hero-6-img">
                                        <img className="img-fluid" src={require(`../../assets/images/${props.banner_main_img}`)} alt="hero" />
                                    </div>
                                </div>
                                : ''
                        }
                    </div>
                </div>
            </section>
        </>
    );
}

export default Banner6;
