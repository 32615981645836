import React from 'react';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  --Background Like this class "bg_pattern_01 | bg_pattern_02 | bg_pattern_03" | bg_purple_img | bg_sblue_img | bg_selfy | blue_gradient. 
//                         Other options find in style.css(Background Colors && Background Gradients && Background Images for Sections) 
//                      --ADD extra class like (pt-1/100, pb-1/100, pl-1/100, pr-1/100, "Your Own Class").

// (2) font_color = Content font color like (white-color | dark-color | grey-color | lightgrey-color | blue-color | green-color | purple-color | rose-color | sapphire-color | skyblue-color | stateblue-color | violet-color | yellow-color)
// (3) banner_app_logo = App logo
// (4) banner_title = Banner title
// (5) banner_description = Banner discription
// (6) app_store_img = App store image
// (7) appstore_link = App store link
// (8) play_store_img = Play store image
// (9) playstore_link = play store link
// (10) banner_content_img = Banner side section image

const Banner1 = (props) => {
    return (
        <>
            <section id="hero-1" className={`hero-section division ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className={`container ${!props.font_color == '' ? props.font_color : ''}`}>
                    <div className="row d-flex align-items-center">
                        <div className={`${props.banner_content_img == '' ? 'col-12 text-center' : 'col-md-7 col-lg-6'}`}>
                            <div className={`hero-1-txt${props.banner_content_img == '' ? 'mt-4' : 'pl-25'}`}>

                                {/* App Logo */}
                                {
                                    !props.banner_app_logo == ''
                                        ?
                                        <div className="hero-app-logo">
                                            <img className="img-fluid" src={require(`../../assets/images/${props.banner_app_logo}`)} alt="hero-app-logo" />
                                        </div>
                                        : ''
                                }

                                {/* Banner Title  */}
                                {!props.banner_title == '' ? <h2 className="h2-title-xs">{props.banner_title}</h2> : ''}

                                {/* Banner Description */}
                                {!props.banner_description == '' ? <p>{props.banner_description}</p> : ''}

                                {/* Store Badges */}
                                <div className="stores-badge">
                                    {/* App store image */}
                                    {
                                        !props.app_store_img == ''
                                            ?
                                            <a href={`${!props.appstore_link == '' ? props.appstore_link : '#/'}`} className="store">
                                                <img className="appstore" src={require(`../../assets/images/${props.app_store_img}`)} alt="appstore-badge" />
                                            </a>
                                            : ''
                                    }

                                    {/* Play store image */}
                                    {
                                        !props.play_store_img == ''
                                            ?
                                            <a href={`${!props.playstore_link == '' ? props.playstore_link : '#/'}`} className="store">
                                                <img className="googleplay" src={require(`../../assets/images/${props.play_store_img}`)} alt="googleplay-badge" />
                                            </a>
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>

                        {/* Banner Main Image */}
                        {
                            !props.banner_content_img == ''
                                ?
                                <div className="col-md-5 col-lg-6">
                                    <div className="hero-1-img text-center wow fadeInLeft" data-wow-delay="0.6s">
                                        <img className="img-fluid" src={require(`../../assets/images/${props.banner_content_img}`)} alt="hero" />
                                    </div>
                                </div>
                                : ''
                        }
                    </div>
                </div>
                <div className="bg_fixed incline_overlay"></div>
            </section>
        </>
    );
}
export default Banner1;
