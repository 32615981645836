import React from 'react';
import WOW from "wowjs";
import Banner2 from '../components/main-banner/Banner2';
import Brand from '../components/Brand';
import Download_bgImage_Banner from '../components/banner/Download_bgImage_Banner';
import Download_bgPattern_Banner from '../components/banner/Download_bgPattern_Banner';
import Faq_simple from '../components/Faq_simple';
import NewsLetter from '../components/NewsLetter';
import Pricing from '../components/Pricing';
import Reviews from '../components/Reviews';
import Features_Info from '../components/Features_Info';
import Accessible_All_Platform_Banner from '../components/Accessible_All_Platform_Banner';
import Info_Content_Img from '../components/Info_Content_Img';
import Blog from '../components/Blog';
import Info_Img_Content from '../components/Info_Img_Content';
import InfoBannerLeft from '../components/InfoBanner_left';
import ProgessbarSec from '../components/Progessbar_sec';


class Home2 extends React.Component {
  componentDidMount() {
    const wow = new WOW.WOW(
      { live: false }
    );
    wow.init();
  }
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">

          {/* BANNER-TWO */}
          <Banner2
            // All Props information is preset in "components/main-banner/Banner2.jsx"
            section_class={''}
            banner_image={`home/hero-2-img.png`}
            banner_image_btn_link={``}
            font_color={`white-color`}
            banner_title={`Make your voice assistant more personal`}
            banner_description={`Aliqum  mullam blandit tempor sapien gravida donec ipsum porta justo. Velna vitae auctor congue magna nihil impedit ligula risus. Mauris donec ociis magnis sapien sagittis sapien sem congue tempor`}
            app_store_img={`store/appstore.png`}
            appstore_link={``} // appstore link
            play_store_img={`store/googleplay.png`}
            playstore_link={``}
            version_desciption={`Current Version 2.1.09, iOS X 10.10+`}
          />

          {/* FEATURES-INFO */}
          <Features_Info
            // All Props information is preset in "components/Features_info.jsx"
            layout_style={2}
            section_class={`wide-50`}
            sec_title={`Get Ready to Be Surprised`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            info_data={[
              {
                icon: `flaticon-ads`,
                title: `Friendly Interface`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-switch`,
                title: `Powerful Options`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-pantone`,
                title: `Customization`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-user-1`,
                title: `Multiple Accounts`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-browser`,
                title: `Register in 30 Seconds`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-fingerprint`,
                title: `Secure Access`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              }
            ]}
            icon_color={'skyblue-color'}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG*/}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`mb-40`}
            sub_title={`Perfect Integration`}
            title={`Work smarter with powerful features`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`,
              ]
            }
            rtl_image={`home/img-02.png`}
            img_Class={`pc-15`}
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`pb-100`}
            lft_image={`home/img-01.png`}
            img_Class={``}
            sub_title={`Register in 30 Seconds`}
            title={`Lightning fast and super powerful`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue
                luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat
                sodales a sapien`,
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`content_bg_8 rel mt-inverse-40`}
            sub_title={`Unlimited Access`}
            title={`More productivity with less effort`}
            p_content_data={
              [
                `Gravida porta velna vitae auctor congue at magna nihil impedit ligula risus mauris donec a ligula risus`,
                `Aliqum mullam blandit and tempor sapien donec lipsum gravida porta. Velna vitae auctor congue magna impedit ligula risus. Mauris donec ligula an impedit magnis`,
              ]
            }
            rtl_image={`home/img-08.png`}
            img_Class={`pc-15`}
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* FEATURES-INFO */}
          <Features_Info
            // All Props information is preset in "components/Features_info.jsx"		
            layout_style={1}
            section_class={`wide-60`}
            sec_title={`Stay Organized Effortlessly`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            info_data={[
              {
                title: `Innovative Design`,
                description: `Porta semper lacus cursus a feugiat primis ultrice ligula and risus auctor tempus feugiat impedit impedit`
              },
              {
                title: `Cross-Platform`,
                description: `Porta semper lacus cursus a feugiat primis ultrice ligula and risus auctor tempus feugiat impedit impedit`
              },
              {
                title: `Fast Performance`,
                description: `Porta semper lacus cursus a feugiat primis ultrice ligula and risus auctor tempus feugiat impedit impedit`
              },
              {
                title: `Multi-device Syncing`,
                description: `Porta semper lacus cursus a feugiat primis ultrice ligula and risus auctor tempus feugiat impedit impedit`
              },
              {
                title: `Battery Saving`,
                description: `Porta semper lacus cursus a feugiat primis ultrice ligula and risus auctor tempus feugiat impedit impedit`
              },
              {
                title: `Multi-language Support`,
                description: `Porta semper lacus cursus a feugiat primis ultrice ligula and risus auctor tempus feugiat impedit impedit`
              }
            ]}
          />

          {/* INFO BANNER-LEFT */}
          <InfoBannerLeft
            // All Props information is preset in "components/InfoBannerLeft.jsx"						
            section_bg_class={`bg_sapphire bg_pattern_02`}
            font_color={`white-color`}
            sec_img={`home/img-04.png`}
            sub_title={`Fastest Messaging`}
            title={`Send text, voice, picture messages more faster`}
            content={`Aliqum mullam blandit and tempor sapien donec ipsum gravida porta. Velna vitae auctor congue magna impedit ligula risus. Mauris donec ligula and magnis undo sapien sagittis sapien pretium enim gravida purus ligula`}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`wide-60`}
            sub_title={`Totally Optimized`}
            title={`Intuitive features, powerful results`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`,
              ]
            }
            button={true}
            button_bg_and_hover={`btn-tra-grey skyblue-hover`}
            button_content={`Find Out More`}
            rtl_image={`home/tablet.png`}
            img_Class={`content-13-img`}
            bgShape_design_1={''}
            bgShape_design_2={''}
          />

          {/* PROCESSBAR */}
          <ProgessbarSec
            // All Props information is preset in "components/Progessbar_sec.jsx"
            section_class={`pb-100`}
            sec_title={`Get fast and secure access to all your favorite content`}
          />

          {/* ACCESSIBLE ALL PLATFORM */}
          <Accessible_All_Platform_Banner
            // All Props information is preset in "components/Accessible_All_Platform_Banner.jsx"
            section_class={`bg_cls whitesmoke_shape `}
            sec_title={`Accessible for All Platforms`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            sec_img={`home/browsers.png`}
            button={``}
            button_title={``}
            button_bg_and_hover={``}
            button_icon={``}
            button_description={``}
          />

          {/* BRANDS-2 */}
          <Brand
            // All Props information is preset in "components/Brand.jsx"
            section_class={``}
            sec_title={`You might know Descode from:`}
            brand_images={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          />

          {/* REVIEWS SIMPLE */}
          <Reviews
            // All Props information is preset in "components/Reviews.jsx"
            section_class={`bg_aliceblue wide-60`}
            section_title={`Why People Use DesCode®`}
            section_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
            app_store_img={`store/appstore.png`}
            appstore_link={``}
            play_store_img={`store/googleplay.png`}
            playstore_link={``}
            app_version_desc={`Available for iOS 8 and Android Devices From 5.5`}
          />

          {/* PRICING */}
          <Pricing
            // All Props information is preset in "components/Pricing.jsx"		
            section_class={`wide-100 `}
            sec_title={`Simple and Flexible Pricing`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
          />

          {/* DOWNLOAD BG IMAGE */}
          <Download_bgImage_Banner
            // All Props information is preset in "components/Download_bgImage_Banner.jsx"		
            section_class={`bg_mobile`}
            sub_title={`Built For Speed`}
            title={`Share text, voice, photos, videos, GIFs and files for free`}
            description={`Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit 
                              posuere orci auctor purus euismod an aliquam quaerat purus`}
            img1={'store/appstore.png'}
            img2={'store/googleplay.png'}
          />

          {/* FAQS-SIMPLE*/}
          <Faq_simple
            // All Props information is preset in "components/Faq_simple.jsx"	
            section_class={`wide-100`}
            sec_title={`Got Questions? Look Here`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
          />

          {/* NEWSLETTER */}
          <NewsLetter
            // All Props information is preset in "components/NewsLetter.jsx"	
            section_class={``}
            sub_title={`Subscribe to Our Newsletter`}
            title={`Stay up to date with our news, ideas and updates`}
            input_placeholder={``}
            btn_content={`Subscribe Now`}
            button_bg_and_hover={`btn-skyblue tra-skyblue-hover`}
          />

          {/* BLOG */}
          <Blog
            // All Props information is preset in "components/Blog.jsx"	
            section_class={``}
            sec_title={`Our Tips and Latest News`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
            itemStart={5}
            itemEnd={8}
          />

          {/* DOWNLOAD BG PATTERN */}
          <Download_bgPattern_Banner
            // All Props information is preset in "components/Download_bgPattern_Banner.jsx"	
            section_class={`bg_sblue bg_pattern_03 pt-100`}
            sec_img={`home/img-15.png`}
            title={`Save More Time with Automation`}
            content={`Augue egestas volutpat egestas augue purus cubilia laoreet and magna suscipit luctus dolor tempus`}
            img1={'store/appstore.png'}
            img2={'store/googleplay.png'}
            user={`7.296`}
          />

        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default Home2;