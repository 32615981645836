import React from 'react';
//*** COMPONET PROPS INFO: ***//

// (1) section_class =  Background Like this class "bg_chat | bg_fit_01 | bg_fit_02 | bg_mobile | bg_bank_01 | bg_bank_02 | bg_purple_img | bg_sblue_img | bg_selfy | bg_pattern_01 | bg_pattern_02 | bg_pattern_03"
//                    --ADD extra class like pt-1/100, pb-1/100, pl-1/100, pr-1/100 and "Your Own Class".
//                    --font color like (white-color | dark-color | grey-color | lightgrey-color | blue-color | green-color | purple-color | rose-color | sapphire-color | skyblue-color | stateblue-color | violet-color | yellow-color)
// (2) sec_img = Content image
// (3) sub_title = Section sub title    
// (4) title = Section title    
// (5) content = Section description
// (6) img1 = App store image
// (7) img1_link = App store link
// (8) img2 = Play store image
// (9) img2_link = play store link
// (10) user = version description in total user

const Download_bgPattern_Banner = (props) => {
  return (
    <>
      <section id="download-1" className={`${!props.section_class == '' ? props.section_class : ''} ${props.sec_img == undefined ? 'pb-100' : ''} download-section division`}>
        <div className="container white-color">
          <div className="row d-flex align-items-center m-row">
            {/* Section main image */}
            {
              !props.sec_img == ''
                ?
                <div className="col-md-5 col-lg-6 m-bottom">
                  <div className="img-block right-column pc-25 wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-fluid" src={require(`../../assets/images/${props.sec_img}`)} alt="content" />
                  </div>
                </div>
                :
                ''
            }
            <div className={`m-top ${props.sec_img == undefined ? 'col-12 text-center' : 'col-md-7 col-lg-6'}`}>
              <div className="txt-block left-column pc-20 wow fadeInLeft" data-wow-delay="0.6s">

                {/* title */}
                {!props.title == '' ? <h2 className="h2-md">{props.title}</h2> : ''}

                {/* description */}
                {!props.content == '' ? <p className="p-xl">{props.content}</p> : ''}


                <div className="stores-badge">
                  {/* Download image-1 */}
                  {
                    !props.img1 == '' ?
                      <a href={!props.img1_link == '' ? !props.img1_link : '#/'} className="store">
                        <img className="appstore-white" src={require(`../../assets/images/${props.img1}`)} alt="appstor" />
                      </a>
                      : ''
                  }
                  {/* Download image-2 */}
                  {
                    !props.img2 == '' ?
                      <a href={!props.img2_link == '' ? !props.img2_link : '#/'} className="store">
                        <img className="appstore-white" src={require(`../../assets/images/${props.img2}`)} alt="googlepla" />
                      </a>
                      : ''
                  }
                </div>

                <div className="txt-block-rating">
                  <div className="stars-rating">
                    <span className="flaticon-star ml-5" />
                    <span className="flaticon-star" />
                    <span className="flaticon-star" />
                    <span className="flaticon-star" />
                    <span className="flaticon-star-half-empty" />
                    {
                      !props.user == '' ?
                        <p className="txt-rating">Based orrn ${props.user} user reviews (App Store &amp; Google Play)</p>
                        : ''
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Download_bgPattern_Banner;
