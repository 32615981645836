import React from 'react';

//*** COMPONET PROPS INFO: ***//

// (1) section_class =  Background Like this class "bg_chat | bg_fit_01 | bg_fit_02 | bg_mobile | bg_bank_01 | bg_bank_02 | bg_purple_img | bg_sblue_img | bg_selfy | bg_pattern_01 | bg_pattern_02 | bg_pattern_03"
//                      ADD extra class like pt-1/100, pb-1/100, pl-1/100, pr-1/100 and "Your Own Class".
// (2) sub_title = Section sub title    
// (2) title = Section title    
// (3) description = Section description
// (4) font_color = font color like (white-color | dark-color | grey-color | lightgrey-color | blue-color | green-color | purple-color | rose-color | sapphire-color | skyblue-color | stateblue-color | violet-color | yellow-color)

const Download_bgImage_Banner = (props) => {
    return (
        <>
            <section className={`wide-100 download-section division ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className={`container ${!props.font_color == '' ? props.font_color : 'white-color'}`}>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="download-6-txt pl-20 wow fadeInUp" data-wow-delay="0.6s">

                                {/* sub title */}
                                {!props.sub_title == '' ? <span className="section-id">{props.sub_title}</span> : ''}

                                {/* title */}
                                {!props.title == '' ? <h2 className="h2-md">{props.title}</h2> : ''}
                                
                                {/* description */}
                                {!props.description == '' ? <p className="p-xl">{props.description}</p> : ''}

                                {/* play|app-store images */}
                                <div className="stores-badge">
                                    {
                                        !props.img1 == '' ? <a href="#/" className="store"><img className="appstore" src={require(`../../assets/images/${props.img1}`)} alt="appstore" /></a> : ''
                                    }
                                    {
                                        !props.img2 == '' ? <a href="#/" className="store"><img className="googleplay" src={require(`../../assets/images/${props.img2}`)} alt="googleplay" /> </a> : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Download_bgImage_Banner;
