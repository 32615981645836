import $ from "jquery";

/*------------------------*/
/*	Navigtion Menu Scroll
/*------------------------*/
$(window).on('scroll', function () {

	var b = $(window).scrollTop();

	if (b > 80) {
		$(".wsmainfull").addClass("scroll");
	} else {
		$(".wsmainfull").removeClass("scroll");
	}
});


