import React from 'react';
import WOW from "wowjs";
import ReviewSlider from '../components/Reviews-Slider';
import MoreApps from '../components/MoreApps';
import Download_bgImage_Banner from '../components/banner/Download_bgImage_Banner';
import NewsLetter from '../components/NewsLetter';

class MoreAppsPage extends React.Component {
  componentDidMount() {
    const wow = new WOW.WOW(
      { live: false }
    );
    wow.init();
  }
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">

          {/* MORE APPS */}
          <MoreApps
            // All Props information is preset in "components/MoreApps.jsx"
            section_class={`bg_whitesmoke hero-offset-nav pb-70`}
            sec_title={`Other Apps by CODEXThemes`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
          />

          {/* DOWNLOAD BG_IMAGE BANNER*/}
          <Download_bgImage_Banner
            // All Props information is preset in "components/Download_bg_img.jsx"
            section_class={'bg_mobile'}
            font_color={`white-color`}
            sub_title={'Best Solutions'}
            title={`Solutions Rooted in Code and Design`}
            description={`Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit 
								posuere orci auctor purus euismod an aliquam quaerat purus`}
            img1={'store/appstore.png'}
            img2={'store/googleplay.png'}
          />

          {/* REVIEWS-SLIDER*/}
          <ReviewSlider
            // All Props information is preset in "components/Reviews-Slider.jsx"
            section_class={`background_bg`}
            sub_title={`Golden Reviews`}
            sec_title={`Our Happy Customers`}
            sec_description={`Aliquam augue suscipit luctus neque purus ipsum neque dolor primis undo tempus, blandit and cursus varius`}
          />

          {/* NEWSLETTER */}
          <NewsLetter
            // All Props information is preset in "components/NewsLetter.jsx"	
            section_class={`pt-50`}
            sub_title={`Subscribe to Our Newsletter`}
            title={`Stay up to date with our news, ideas and updates`}
            input_placeholder={``}
            btn_content={`Subscribe Now`}
            button_bg_and_hover={`btn-skyblue tra-skyblue-hover`} //Like btn-tra-grey skyblue-hover					
          />

        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default MoreAppsPage 