import React from 'react';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Background Like this class "bg_chat | bg_fit_01 | bg_fit_02 | bg_mobile | bg_bank_01 | bg_bank_02 | bg_purple_img | bg_sblue_img | bg_selfy | bg_pattern_01 | bg_pattern_02 | bg_pattern_03"
//                      ADD extra class like pt-1/100, pb-1/100, pl-1/100, pr-1/100 and "Your Own Class".
// (2) sec_title = Section title
// (3) sec_description = Section description

const Pricing = (props) => {
  return (
    <>
      <section id="pricing-2" className={`pricing-section division ${!props.section_class == '' ? props.section_class : ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="section-title text-center mb-70">
                {!props.sec_title == '' ? <h2 className="h2-md">{props.sec_title}</h2> : ''}
                {!props.sec_description == '' ? <p className="p-xl">{props.sec_description}</p> : ''}
              </div>
            </div>
          </div>
          <div className="row pricing-row">            
            <div className="col-md-4">
              <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.4s">
                <div className="pricing-plan bg_white">
                  <h5 className="h5-xs">Descode Free</h5>
                  <sup className="dark-color">$</sup>
                  <span className="dark-color">0</span>
                  <sup className="validity dark-color"><span>.00</span> / month</sup>
                  <p className="p-sm">The price per one user. Change or cancel your plan anytime</p>
                  <a href="#/" className="btn btn-tra-grey skyblue-hover">Download Now</a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.6s">
                <div className="pricing-plan bg_white">
                  <h5 className="h5-xs">Monthly Billing</h5>
                  <sup className="dark-color">$</sup>
                  <span className="dark-color">6</span>
                  <sup className="validity dark-color"><span>.25</span> / month</sup>
                  <p className="p-sm">The price per one user. Change or cancel your plan anytime</p>
                  <a href="/descode/pricing" className="btn btn-tra-grey skyblue-hover">Select Plan</a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pricing-table rel mb-40 wow fadeInUp" data-wow-delay="0.8s">
                <div className="badge-wrapper">
                  <div className="highlight-badge bg_skyblue white-color">
                    <h6 className="h6-sm">Save up to 25%</h6>
                  </div>
                </div>
                <div className="pricing-plan highlight bg_whitesmoke">
                  <h5 className="h5-xs">Annual Billing</h5>
                  <sup className="dark-color">$</sup>
                  <span className="dark-color">5</span>
                  <sup className="validity dark-color"><span>.69</span> / month</sup>
                  <p className="p-sm">The price per one user. Change or cancel your plan anytime</p>
                  <a href="/descode/pricing" className="btn btn-tra-grey skyblue-hover">Select Plan</a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="pricing-2-download-btn mt-20 text-center wow fadeInUp" data-wow-delay="0.8s">
                <a href="/descode/pricing" className="btn btn-md btn-tra-grey skyblue-hover mb-10">Start Free 14-day Trial</a>
                <span className="os-version">Request OS X 10.10 or later</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Pricing;
